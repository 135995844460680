.dialogForm {
    width: 100%;

}
.formContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}
.form_field {
    width: 40% !important;
}