.font {
  font-size: 20px;
}

.button {
  padding: 40px 0 0 0;
}

// .divStyle {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   /* width: 1281px; */
//   height: 80%;
// }

// .imageStyles {
//   height: 100vh;
//   background-image: url("./school3.png");
//   background-repeat: no-repeat;
//   background-size: contain;
// }
.fontStyle {
  font-size: 12px;
}
.imageStyless {
  height: 100vh;

  background-image: url("../../../assets/Images/loginimage.png");

  -webkit-background-size: cover;

  background-repeat: no-repeat;

  background-size: cover;
}

.divStyle {
  display: flex;

  justify-content: center;

  align-items: center;

  display: grid;
}

.form {
  background-color: transparent;

  place-content: center;

  border: 3px solid rgba(248, 245, 245, 0.226);

  border-radius: 20px;

  box-sizing: border-box;
}
.box {
  background-color: #ffff;
  color: black;
}
