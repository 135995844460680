.css-19kzrtu {
    padding: 10px 0 0 0 !important;
}

.examfilter-searchbar {
    // display: flex;
    margin: 15px;
    // gap: 10px;

}

.exam-searchbar {
    display: flex;
    justify-content: flex-end;

    svg:not(:root) {
        color: #1b3779 !important;
    }
}
.notfoundStyle {
    min-height: 74vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white
  }