.title:hover{
    color: #f86f03;
    font-weight: bold;
}
.container:hover .date{
    color: #033b62;
    font-weight: bold;
}
.container:hover .timeIcon {
    color: #033b62;
    font-weight: bold;
}

.container:hover .time {
    color: #f86f03;
    font-weight: bold;
}

.container{
    margin-top: 3rem;
}

.title{
    font-weight: bold;
}