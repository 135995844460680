.container {
    padding: 1rem;
}
.cardForm {
    margin: 1rem 0;
    padding-bottom: 1rem;
}
.dueInfoContainer {
    padding: 1rem 10px;
}
.dueInfoContainerFlex {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}
.tableContainer {
    margin-top: 1rem;
}
.addRowBtn {
    margin: 10px 5px;
    display: flex;
    justify-content: space-between;
}
.addRowBtn_childFlex {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.concessionText {
    font-weight: bold;
}