@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
.wrapper {
  position: relative;
  min-width: 500px;
  width: 100%;
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.wrapper h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

.wrapper h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 28px;
  border-radius: 12px;
}

.wrapper form {
  margin-top: 30px;
}

.wrapper form .input-box {
  height: 52px;
  margin: 18px 0;
}

form .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 15px;

  font-size: 17px;

  font-weight: 400;

  color: #333;

  border: 1.5px solid #c7bebe;

  border-bottom-width: 2.5px;

  border-radius: 6px;

  transition: all 0.3s ease;
}

.input-box input:focus,
.input-box input:valid {
  border-color: #4070f4;
}

form .policy {
  display: flex;
  align-items: center;
}

form h3 {
  color: #707070;

  font-size: 14px;

  font-weight: 500;

  margin-left: 10px;
}

.input-box.button button {
  color: #fff;
  width: 100%;
  letter-spacing: 1px;
  padding: 10px;
  border: none;
  background: #0e4bf1;
  cursor: pointer;
}

.input-box.button button:hover {
  background: #f86f03;
}

form .text h3 {
  color: #333;
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

form .text h3 a {
  color: #4070f4;
  margin-top: 40px;

  text-decoration: none;
}

form .text h3 a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .wrapper {
    min-width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .wrapper {
    min-width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .wrapper {
    min-width: 280px;
  }
}
