.container {
  box-shadow: "0 0 30px rgba(0, 0, 0, 0.05)";
  margin-top: 0.7rem;
}
.date {
  font-weight: 600;
  /* text-align: center; */
}
.title {
  font-weight: bold;
}
.title:hover {
  /* color:#80CD36; */
  color: #f86f03;
  font-weight: bold;
}
.container:hover .date {
  color: #033b62;
  font-weight: bold;
}
.container:hover .timeIcon {
  color: #033b62;
  font-weight: bold;
}

.container:hover .time {
  color: #f86f03;
  font-weight: bold;
  /* color:#e91e63; */
}

.container:hover .readMore {
  /* color: #80CD36; */
  color: #f86f03;
}

.container:hover .chevron {
  color: #f86f03;
}

.cardimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
