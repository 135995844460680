.infrastructure{

.infrastructure-title {
    margin-bottom: 1em;
    color: #f07b47;
}

.main-container_infra {
    color: black;
    position: relative;
    margin-bottom: 20px;
    text-align: center;
}

.infra-title {
    margin-top: 10px;
    font-weight: 700;
}






.all-line-1 {
    margin-top: -39px;
    margin-right: 221px;

    .first-line {
        border: 1px solid #f07b47;
        display: inline-block;
        background: #f07b47;
        border-radius: 8px;
        width: 20px;
    }

    .middle-line {
        display: inline-block;
        height: 6px;
        border: 1px solid #f07b47;
        margin: 0px 0px;
        margin-bottom: -2px;
        width: 42px;
        background: #f07b47;
        border-radius: 6px;
    }

    .last-line {
        width: 41px;
    }
}
}