.hwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #3e68ff;
}

/* (B) MOVING TICKER WRAPPER */
.hmove {
  display: flex;
}

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
/* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
@keyframes tickerh {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-400%, 0, 0);
  }
}
.hmove {
  animation: tickerh linear 15s infinite;
}
.hmove:hover {
  animation-play-state: paused;
}
