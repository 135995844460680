@import "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;500&display=swap";

.topButton:hover {
  background-color: #198eeb;
}

/* .topButton {
  padding: 14px 5px 14px 5px;
  height: 45px;
  
} */

.toolHeight {
  height: 60px !important;
}

.firstHalf:hover {
  color: #ff6d34;
}
.secHalf {
  color: white
}
.secHalf:hover {
  color: rgb(235, 95, 44)
}


/* .navContent {
  display: flex;
  

} */

.content {
  color: #ffff;
}

.content li {
  list-style: none;
  padding: 20px 15px;
  float: left;
  margin: 0px 10px 0px 0px;
  text-align: center;
  font-family: inherit;
}

.content li p {
  font-size: 15px;
  font-family: sans-serif;
  cursor: pointer;
}

/* .navContents {
  display: flex;
 justify-content: space-between;
  align-items: center;
} */

.navContent ul li::after {
  content: '';

  width: 0%;
  height: 2px;
  background-color:white;
  display: block;
  /* margin: auto; */
  transition: 0.5s;
}

.navContent ul li:hover::after {
  width: 100%;
}




.divider {
  max-height: 35px;
  color: orangered;
  width: 5px;
  font-size: 5px;
  border: 1px solid orangered;
  border-radius: 2px;
  background-color: orangered;
  font-weight: 600px;
}

.title {
  font-size: 25px;
  /* margin-top: 8px; */
  margin-left: 4px
}

.sideContent {
  color: #fff;
  margin-left: 10%;

}

.sideTitle {
  font-size: 50px;
  color: #fff;
  font-weight: 600px;
  margin-left: 10%;

  letter-spacing: 0.2rem;
  font-family: 'Roboto Slab', serif;
}


.squareBox {
  color: orangered;
  margin-top: 15px;
}

.coursBtn {
  background-color: #00bda6;
  color: #fff;
  text-transform: capitalize;
  margin-left: 10%;
}


.navContent ul menu menuitem:hover {
  color: #f44336;
}

.footerContent1 {
  color: #fff;
  font-size: 16px;
  font-weight: 10px;
}

.footerContent1:hover {
  color: #00bda6;
}


.footerContent a::after {
  content: '';
  height: 0.5px;
  width: 0%;
  background-color: #00bda6;
  display: block;
  margin-top: -10%;
  transition: 0.2s;
}

.footerContent a:hover::after {
  width: 70%;
}


.impLink {
  font-family: 'Roboto Slab', serif;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}

.find {
  font-family: 'Roboto Slab', serif;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-left: 50px;
  color: white;
  text-align: center;
  font-size: 30px
}

.divider1 {
  max-height: 35px;
  color: #fff;
  width: 5px;
  font-size: 5px;
  font-weight: 600px;
}

.rotate {
  animation: rotation 100s infinite linear;
  font-size: 16px;
  font-weight: 400;
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
.glow-button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  background-color: #00bda6;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
}

.glow-button:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-color: #00bda6;
  transform: translate(-50%, -50%);
  opacity: 0;
  border-radius: 50%;
  animation: glowing 2s linear infinite;
}

.glow-button:hover:before {
  opacity: 1;
}

.glow-button-text {
  position: relative;
  z-index: 1;
}

@keyframes glowing {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}


.rotate1 {
  animation: rotation 100s infinite linear;
  font-size: 16px;
  font-weight: 400;
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.kayakaa {
  background-color: #fff !important;
  color: #000 !important;
}
.kayakaa:hover {
  background-color: aqua  !important;
}

.topper-btn button {

  background: #fff;

  border-color: #ddd;

  border-width: 0px;

  cursor: pointer;

  margin: 7px;

  font-size: 12px;

  font-weight: 500;

}




.topper-btn button:hover {

  color: #564fff;

}




.topper-btn i {

  color: #564fff;

}
