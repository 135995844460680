.container-about-school {
    background: #33415c;
    padding: 70px;
    color: #fff;
    position: relative;
    margin-bottom: 20px;
}

.about-school-title {
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
}

.container-about-school {
    p {
        color: #fff;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: 0.5px;
        margin-bottom: 2em;
    }
}

.view-more-btn button {
    cursor: pointer;
    color: #fff;
    position: relative;
    z-index: 222;
    margin-left: 30px;
    background: transparent;
    width: 148px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    display: inline-flex;
    border: 1px solid #fff;
}

.view-more-btn button:hover {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.view-more-btn {
    margin-left: -60px;
    margin-bottom: -40px;
}