*,
*::before,
*::after {
  /* margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none; */
}

:root {
  /* --primary: #6a59ff; */
  /* --white: #ffffff; */
  /* --bg: #f5f5f5; */
}

html {
  /* font-size: 62.5%; */
  /* scroll-behavior: smooth; */
}

/* @media (min-width: 1440px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.7;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5;
  }
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
} */

/* body {
  font-size: 1.6rem;
  background: var(--bg);
} */

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  /* font-size: 3.5rem; */
  text-align: center;
}

.swiper_container {
  height: 32rem;
  padding: 2rem 0;
  width:51rem;
  position: relative;
  /* border: solid red; */
  /* border: none; */
}
/* CoverflowSlider.css */

/* ... (existing styles) ... */

.swiper-slide {
    /* ... (existing styles) ... */
    transform: scale(0.6); /* Apply a default scale to all slides */
    transition: transform 0.5s; /* Add a transition effect for smooth zooming */
    
  }
  
  .swiper-slide-active {
    transform: scale(0.7); /* Apply a larger scale to the active slide */
    z-index: 1; /* Ensure the active slide appears on top */
  }
  
  /* ... (existing styles) ... */
  
.swiper-slide {
  padding:20px;
  /* max-height: 800px; */
  margin-top: -2%;

  width: 17rem;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

@media (max-width: 500px) {
  .swiper_container {
    height: 55rem;
    width: 100%;
    margin-top:50%;
  }
  .swiper-slide {
    width: 8rem !important;
    height: 36rem !important;
    
 
  }
  .swiper-slide img {
    width: 20rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}