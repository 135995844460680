.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("https://images.unsplash.com/photo-1533901567451-7a6e68d6cd8f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  /* backgroundRepeat:"no-repeat";
   width:"100%";
   backgroundSize:"100% 100%";
  paddingTop:"250px";*/
  background-size: cover;
  background-size: "100% 100%";
  background-repeat: no-repeat;
}
