// .bordered {
//   display: inline-block;
//   position: relative;
//   padding-bottom: 3px;
//    text-align: center;
// //    text-indent: 40px;
//   margin-bottom: 3px;

// }

// .bordered::after {
//   content: "";
//     margin-bottom: 1px;

//   position: absolute;
//   bottom: 0;
//   left: 0;
// //    text-indent: 40px;
//   width: 100%;
//   border-bottom: 1px dotted black;
// }

.bordered {
  //   display: inline-block;
  //   position: relative;
  padding-bottom: 3px;
  margin-bottom: 10px;

  border-bottom: 1px dotted black;
}

// .bordered::after {
//   content: "";
//    margin-bottom: 1px;
//   position: absolute;
//   bottom: 0;
//  left:0;
//   width: 100%;
//   border-bottom: 1px dotted black;
// }
