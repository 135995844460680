* {
    box-sizing: border-box;
}

.about-home {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    justify-content: flex-end;
    height: 100%;
    width: 50%;
    // text-align: center;
    padding-right: 15px;
    padding-left: 15px;
}

.about-us {
    width: 400px;
    background: rgba(198, 154, 71, .58);
    padding: 20px 30px;


    h1 {
        color: white;
        font-size: 25px;
        font-weight: 700;
    }
}

.home {
    color: white;
    cursor: pointer;
}

.about-content {
    display: flex;
    margin-bottom: 60px;
}

.about-container {
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 10%;
    margin-bottom: 60px;
}

.scroll-container {
    // border: 3px solid black;
    border-radius: 5px;
    // height: 50%;
    // overflow: hidden;
    // width: 50%;
    margin: 18px;
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);

    -moz-animation: my-animation 90s linear infinite;
    -webkit-animation: my-animation 90s linear infinite;
    animation: my-animation 90s linear infinite;
    animation-play-state: running;

}

.scroll-container:hover {
    // border: 3px solid black;
    border-radius: 5px;
    // height: 50%;
    // overflow: hidden;
    // width: 50%;
    margin: 18px;
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);

    -moz-animation: my-animation 90s linear infinite;
    -webkit-animation: my-animation 90s linear infinite;
    animation: my-animation 90s linear infinite;
    animation-play-state: paused;
    .title-discription1:hover{
        text-decoration: underline;
    }
}

.news-container{
    overflow: hidden;
    height: 500px;
    padding-top: -20px;
}

.news-title {
    // width: 50%;
    margin-left: 18px;
    // display: flex;
    justify-content: space-around;
}

.news-content {
    background-color: #f7f6f2;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    display: flex;
    height: auto;
    width: 95%;
    margin-left: 2rem;
    padding: 0px 1px;
    min-height: 100px;
    position: relative;
}

.scroll-text {
    height: 100%;
    text-align: center;
    width: 500px;
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    // color: red;

    /* animation properties */

}

/* for Firefox */
@-moz-keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
    }

    to {
        -moz-transform: translateY(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateY(100%);
    }

    to {
        -webkit-transform: translateY(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        -moz-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

.news-about-us {
    max-width: 100%;
    /* min-width: 50%; */
    width: 70%;
    min-width: 30%;
}

.about-info {
    max-width: 60%;
    margin-left: 30px;
    line-height: 28px;
    // margin-top: 20px;

    p {
        margin-top: 20px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
        font-size: 25px;

    }

}

.news-awards {
    width: 100%;
}

.our-mission-card {
    width: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 25%;
    text-align: center;
    height: 200px;
    display: flex;
    // align-items: center;
    background: #f07b47;
    color: #fff;
    margin: 30px;

    p {
        padding: 10px;
    }

    b {
        font-weight: bolder;
        background: white;
        color: #f07b47;
        padding: 7px 47px;
    }

}

.our-mission-container {
    display: flex;
    justify-content: space-around;
    background: #ddd;
}

.our-mission-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.css-1wyzhx5-MuiButtonBase-root-MuiButton-root {
    text-transform: capitalize !important;
}