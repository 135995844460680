.container {
  background: url("/public/image1.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.iconBox {
  color: #068fff;
  font-size: 50px;
  margin-left: 6px;
  margin-top: 5px;
}

.iconBox:hover{
  color:#F86F03;
}
