.date-container {
    display: flex;
    justify-content: space-around;
}


.academic-year-note {
    margin-top: 40px;
    margin-left: 40px;
}

.cancel-submit-btn {
    display: flex;
    justify-content: flex-end;
    // position: fixed;
    bottom: 30px;
    right: 20px;
    gap: 10px;
}