.newsscroll {
  animation: scroll 8s linear infinite; /* Adjust the animation duration as needed */
  display: flex;
  flex-direction: column-reverse;
}
.newscontainer {
  position: relative;
}

.image {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.1);
}

.newscontainer:hover .newsscroll {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
