.list{
    display:flex;
    gap:2rem;
    flex-wrap:wrap;
    justify-content:center;
    margin-top:1.5rem;
}

.list li{
    font-family: sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
    color: #2c2c2c;
    list-style-type: none;
    transition: all 0.5s;
    cursor: pointer;
}

.list li:hover{
    color:#ff4500;
}

