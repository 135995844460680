.add-btn {
    position: fixed;
    width: -moz-fit-content;
    width: fit-content;
    right: 20px;
    bottom: 20px;
}

.form-inline {
    display: flex;
    justify-content: space-between;

    .MuiInputLabel-animated {
        font-size: 12px !important;
    }
}

.button-section {
    margin-top: 30px;
}

.emp-cancel-submit {
    display: flex;
    justify-content: space-between;
}

.emp-add-btn {
    display: flex;
    justify-content: space-between;
}