.dnews {
  font-size: 15px;
  padding-left: 6px;
  color: rgba(240, 7, 7, 0.658);
  border-left: 1px solid grey;
}

.image {
  border-radius: 5px;
  overflow: hidden;
}

.image:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.arc {
  position: absolute;
  z-index: 2;
  clip-path: circle(26.6% at 100% 0);
  background-color: #00bda6;
}

.background {
  background-image: url("https://excellentschool.in/static/media/page-banner-1580211983-sms.eec9af5325dc9cecbb3b.jpg");

  background-repeat: no-repeat;

  background-position: center;

  object-fit: cover;

  height: 40%;

  width: 100%;
  margin-top: 2.5%;

  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;
}
