.container {
    padding: 2rem 2rem;
}
.paper {
    padding: 2rem;
}
.form {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.imageFlex {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}
.imageContainer {
    width: 150px;
    height: 150px;
    position: relative;
}
.noData {
    margin-top: 1rem;
    text-align: center;
}
.btn {
    position: absolute !important;
    top: 3px !important;
    right: 3px !important;
    background: #2d27275e !important;
}
.img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
}