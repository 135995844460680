.class-table-header {
    color: #fff;
}

.css-azw5y5-JoyModal-backdrop {
    background-color: rgb(255 255 255 / 0%) !important;
    -webkit-backdrop-filter: blur(0px) !important;
    backdrop-filter: blur(0px) !important;

}

.css-p0bqzy-JoySheet-root-JoyModalDialog-root {
    background: #fff !important;
    border: none !important;
    box-shadow: 0 0 3px #ddd !important;
    border-radius: 0px !important;
    min-width: min(calc(100vw - 2 * var(--ModalDialog-padding)), var(--ModalDialog-minWidth, 300px)) !important;
}

.css-1k8e1qz-JoyDivider-root {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    font-weight: 700 !important;
    font-size: 10px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    font-size: 12px !important;
    font-weight: 500 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-size: 12px !important;
    font-weight: 500 !important;
}

label+.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 10px !important
}