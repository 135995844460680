.scroll-to-top {
  position: fixed;
  bottom: 35px;
  right: 20px;
  /* width: 40px;
    height: 40px;
    background-color: #333; */
  /* color: #fff; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.scroll-to-top.visible {
  opacity: 0.9;
}

/* Add some icon styles if you're using Font Awesome or any other icon library */
