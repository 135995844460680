.hostel td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.hostel tr:nth-child(even) {
  background-color: #f8f0f0;
}
.borderName {
  font-size: 13px;
  font-weight: 2px;
  border: 1px solid #dddddd;
  padding: 5px;
  width: 100%;
}
.borderPosition {
  font-size: 13px;
  font-weight: 2px;
  border: 1px solid #dddddd;
  padding: 5px;
}

.borderName1 {
  font-size: 13px;
  font-weight: 2px;
  border: 1px solid #dddddd;
  padding: 25px;
  width: auto;
}
