.events{
    margin-left:2rem;
    margin-top:6rem;
    margin-bottom:6rem;
}

.text:hover .typo{

    color: orangered;
}

@media screen and (max-width:600px) {
    .events{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

