.container {
    margin: 1rem;
}
.formContainer {
    padding: 2rem 0;
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.formBtn {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.formHolder {
    width: 60%;
}