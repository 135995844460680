* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.main {
  /* background-image: url("../../assets/backgroung_image.jpg"); */

  /* position: absolute;
      content: "";
      left: 0;
      top: 0; */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.container {
  /* background: url("../../assets/video-bg.jpg"); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  /* z-index: 1; */
}
.vcHeading {
  text-transform: uppercase;
  text-align: center;
  width: 400px;
}
.iconBox {
  color: rgb(128, 205, 54);
  font-size: 50px !important;
}

.mainBox:hover .imageLogo {
  display: flex;
}
.mainBox:hover .department {
  color: #30A2FF;
}
.imageLogo {
  box-sizing: border-box;
}
.mainBox {
  transition: 0.6s ease-in-out;
}
.mainBox:hover .circleImage {
  /* border: 2px solid #80cd36;
    border-radius: 50%;
    overflow: hidden; */

  /* height: 50px;
        width: 50px;
        margin-left: 4px;
        overflow: hidden; */
  /* box-sizing: content-box; */
}
.mainImage {
  /* background-color: rgba(0, 0, 0, 0.5);
    opacity: 0; */
  /* opacity: 0; */
  /* background: rgba(0, 0, 0, 0.6); */
  transition: opacity 0.3s ease-in-out;
}

.mainBox:hover .mainImage {
  opacity: 0.3;
  /* z-index: -1; */
  /* background: rgba(0, 0, 0, 0.6); */

  /* background: rgba(255,255,255,0.3); */
}
.button {
  transition: 0.6s ease-in-out;
}
.mainBox:hover .button {
  display: block;
  /* background-color: red; */
  transition: 0.6s ease-in-out;
}

.circleName:hover {
  color: rgba(172, 255, 47, 0.836);
  cursor: pointer;
}

/* .price {
    border: 1px solid rgba(172, 255, 47, 0.836);
    padding-left: 95px;
    position: relative;
    top: -19px;
    border-left: 0px;
    background: #80cd36;
    color: white;
    clip-path: polygon(10% 0, 90% 1%, 100% 55%, 90% 100%, 10% 100%, 0% 50%);
  } */
