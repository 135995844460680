.navbar__wrapper {
    background: #fff;

    ul {
        list-style: none;
        margin-bottom: 0 !important;
        text-decoration: none;
    }


    a {
        text-decoration: none;
        outline: 0;
    }
}

.mystky {
    position: fixed;
    top: 0;
    left: 0;
    width: 101%;
    z-index: 999;
    transition: all linear 0.9s;
    box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.2);
}

.navbar__inner_container {
    height: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #383838;
}

.mystky .navbar__inner_container {
    align-items: center;
}

.navbar__top {
    background: #fff;
    height: 60px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;

    ul {
        list-style: none;
    }
}

.navbar__top .top__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar__top .top__social li a {
    margin-left: 7px;
    color: #564fff;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    ;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;

}

.navbar__top .top__social li a:hover {
    background: #564fff;
    transform: scale(1);
    color: #fff;
}

.navbar__top .nav__top__left {
    display: flex;
    font-size: 14px;
    font-weight: 600;
}

.navbar__top .nav__top__left li svg {
    margin-right: 3px;
    color: #564fff;
}

.navbar__top .nav__top__left li {
    margin-right: 15px;
}

.menu {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu .hidden {
    justify-content: center;
    text-align: center;
    flex: 0 0 84%;
    margin-right: 60px;
}

.menu .hidden li a {
    position: relative;
}

.menu .hidden li :after {
    position: absolute;
    content: '';
    left: 0%;
    bottom: 8px;
    width: 0%;
    height: 3px;
    background: #564fff;
    transition: all linear 0.3s;
}

.menu .hidden li :hover::after {
    width: 100%;
}

.menu .hidden li .active:after {
    width: 100%;
}

.menu .hidden .active {
    color: #564fff;
}

.logo h4 a {
    font-size: 36px;
    font-weight: 700;
    color: #383838;
}

.menu ul.hidden {
    display: flex;
}

.menu ul li a {
    display: inline-block;
    font-weight: 600;
    margin: 0px 18px;
    padding: 23px 0px;
    color: #fff;
    cursor: pointer;
}

.menu ul li a:hover {
    color: #564fff;
}

.menu ul.hidden li {
    position: relative;
}

ul.vissible {
    display: none;
}

ul.mega {
    position: absolute;
    width: 180px;
    background: #fff;
    transform: translateX(-15px);
    visibility: hidden;
    left: 0;
    top: 108%;
    padding: 15px 0;
    text-align: left;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 2px 6px 2px rgba(60, 64, 67, .15);
    transition: all linear 0.3s;
    z-index: 1;
}

.menu ul.hidden li:hover .mega {
    transform: translateX(0px);
    visibility: visible;
}

ul.mega li a {
    padding: 5px;
}

.mega__visible {
    visibility: visible !important;
    display: block !important;
}

.mega::after,
.mega li a:after,
.mega li:after {
    display: none;
}

.menu ul li a {
    color: #383838;
}

.mobile__menu {
    position: relative;
    cursor: pointer;
    display: none;
}

.mobile__menu span {
    background: #ffab4a;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    ;
    display: block;
}

.mobile__menu:after {
    position: absolute;
    content: "";
    background: #ffab4a;
    width: 23px;
    height: 3px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    ;
    left: 7px;
    bottom: 9px;
}

.contact__btn a {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    ;
    box-shadow: 0 6px 12px 0 rgba(17, 13, 149, 0.3);
    background-color: #564fff;
    color: #fff;
    padding: 12px 15px;
}

.contact__btn {
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;

}

.contact__btn:hover {
    margin-top: -8px;
    align-items: flex-start;
}

.contact__btn:hover a {
    box-shadow: 0 8px 15px 0 rgba(17, 13, 149, 0.3);
}

.contact__btn span {
    padding-right: 8px;
}

.mobile__menu:before {
    position: absolute;
    content: "";
    background: #ffab4a;
    width: 26px;
    height: 3px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    ;
    left: 4px;
    top: 8px;
}

.hidden__corss,
.vissible__cross {
    display: none;
}

.menu {
    img {
        width: 85px;
        margin-left: 60px;
    }
}

.login-btn a {
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    box-shadow: 0 6px 12px 0 rgb(149 86 13 / 0.34%);
    background-color: #f07b47;
    color: #fff;
    padding: 2px 28px 2px 15px;
}

.login-btn {
    i {
        margin: 10px;
    }
}

.login-btn {
    // margin-right: 20px;
    margin-left: -29px;
}
.small-menu{
    .MuiListItem-root{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .MuiListItemText-root{
        padding-right: 0px !important;
    }
    .close-menu{
        display: flex;
    justify-content: end;
    }
}