.headerContainer {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../public/page-banner-1580211983-sms.jpg');
    color: white;
}
.contentContainer {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
}
.contentHead {
    font-weight: bold;
    font-size: 20px;
    margin: 7px ;
    margin-right: 5rem;
}
.contentText {
    margin: 3px 0; 
    font-size: 1.11rem;  
}
.contentHolder {
    /* margin: 2rem 0; */
}
.content {
    width: 65%;
    border-right: 1px solid #BDBDBD;
    margin-right: 1rem;
    padding-right: 1rem;
}