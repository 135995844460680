.mainBox {
  background-image: url("http://iguru.wgl-demo.net/wp-content/uploads/2019/09/home_03-bg.jpg?id=2599");
  /* height: 100vh; */
  width: 100%;
  margin-top: 8rem;
  padding: 80px 0px;
}

.BlogBox {
  background-image: url("http://iguru.wgl-demo.net/wp-content/uploads/2019/09/home_03-bg.jpg?id=2599");
  width: 100%;
  padding: 80px 0px;
}

.btn {
  padding: 0.8rem;
  font-weight: 700;
  border-radius: 5px;
  border-width: 0;
  background-color: white;
  cursor: pointer;
  transition: 0.5s;
}

.btnMain {
  cursor: pointer;
}

.btnMain:hover .btn {
  color: #ff6d34;
}

.btnMain:hover .btns {
  /* visibility: visible; */
  display: "flex";
}

.logo {
  color: #ff6d34;
}
