.latest-event-content .react-multi-carousel-item {
    margin: 10px;

}

.latest-event-content {
    margin-bottom: 60px;

    h1 {
        display: flex;
        justify-content: center;
        margin: 40px;
    }
}

.underline1 {
    border-bottom: 2px solid rgb(116, 36, 36);
    border-width: 90%;
    width: 80px;

}

.first-line {
    border: 1px solid #f07b47;
    display: inline-block;
    background: #f07b47;
    border-radius: 8px;
    width: 20px;
}

.middle-line {
    display: inline-block;
    height: 6px;
    border: 1px solid #f07b47;
    margin: 0px 0px;
    margin-bottom: -2px;
    width: 42px;
    background: #f07b47;
    border-radius: 6px;
}

.last-line {
    width: 41px;
}

.all-line1 {
    margin-top: -39px
}