.AppStudent {
  //text-align: center;
  margin-top: 2%;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 3px;
  margin-bottom: 2px;
  padding-top: -5%;
  margin-top: -8%;
  cursor: pointer;
}

.present {
  background-color: green;
  color: white;
}

.absent {
  background-color: red;
  color: white;
}

.current-date {
  background-color: white;
  color: #000;
}
.current-date:hover {
  background-color: white;
}

.react-calendar__tile--hover {
  background-color: #f7ecec;
}

.react-calendar__tile--active {
  background-color: white;
  color: black;
}

.react-calendar {
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation button {
  color: #000;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;

  border-radius: 6px;
}
.react-calendar__tile--now {
  border-radius: 6px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #ffff;
  border-radius: 6px;

  color: #000;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ffff;
  color: #000;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #000;
  border-radius: 0;
}

.halfday {
  background-color: orangered;
  color: white;
}

.saturday-date {
  color: black;
}

.holiday {
  background-color: orange;
}

.colorBotton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-calendar {
  height: 370px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 3.5em;
}

// .card {
//   background: #0052d4;
//   background: -webkit-linear-gradient(to left, #6fb1fc, #4364f7, #0052d4);
//   background: linear-gradient(to left, #6fb1fc, #4364f7, #0052d4);
// }

// .react-calendar {
//   width: 350px;
//   max-width: 100%;
//   background: white;
//   border: 1px solid #a0a096;
//   font-family: Arial, Helvetica, sans-serif;
//
// .react-calendar {
//   width: 350px;
//   max-width: 100%;
//   background: white;
//   border: 1px solid #a0a096;
//   font-family: Arial, Helvetica, sans-serif;
//   line-height: 1.125em;
// }
// }

// .react-calendar--doubleView {
//   width: 700px;
// }

// .react-calendar--doubleView .react-calendar__viewContainer {
//   display: flex;
//   margin: -0.5em;
// }

// .react-calendar--doubleView .react-calendar__viewContainer > * {
//   width: 50%;
//   margin: 0.5em;
// }

// .react-calendar,
// .react-calendar *,
// .react-calendar *:before,
// .react-calendar *:after {
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
// }

// .react-calendar button {
//   margin: 0;
//   border: 0;
//   outline: none;
// }

// .react-calendar button:enabled:hover {
//   cursor: pointer;
// }

// .react-calendar__navigation {
//   display: flex;
//   height: 44px;
//   margin-bottom: 1em;
// }

// .react-calendar__navigation button {
//   min-width: 44px;
//   background: none;
// }

// .react-calendar__navigation button:disabled {
//   background-color: #f0f0f0;
// }

// .react-calendar__navigation button:enabled:hover,
// .react-calendar__navigation button:enabled:focus {
//   background-color: #e6e6e6;
// }

// .react-calendar__month-view__weekdays {
//   text-align: center;
//   text-transform: uppercase;
//   font-weight: bold;
//   font-size: 0.75em;
// }

// .react-calendar__month-view__weekdays__weekday {
//   padding: 0.5em;
// }

// .react-calendar__month-view__weekNumbers .react-calendar__tile {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 0.75em;
//   font-weight: bold;
// }

// .react-calendar__month-view__days__day--weekend {
//   color: #d10000;
// }

// .react-calendar__month-view__days__day--neighboringMonth {
//   color: #757575;
// }

// .react-calendar__year-view .react-calendar__tile,
// .react-calendar__decade-view .react-calendar__tile,
// .react-calendar__century-view .react-calendar__tile {
//   padding: 2em 0.5em;
// }

// .react-calendar__tile {
//   max-width: 100%;
//   padding: 10px 6.6667px;
//   background: none;
//   text-align: center;
//   line-height: 16px;
// }

// .react-calendar__tile:disabled {
//   background-color: #f0f0f0;
// }

// .react-calendar__tile:enabled:hover,
// .react-calendar__tile:enabled:focus {
//   background-color: #e6e6e6;
// }

// .react-calendar__tile--now {
//   background: #ffff76;
// }

// .react-calendar__tile--now:enabled:hover,
// .react-calendar__tile--now:enabled:focus {
//   background: #ffffa9;
// }

// .react-calendar__tile--hasActive {
//   background: #76baff;
// }

// .react-calendar__tile--hasActive:enabled:hover,
// .react-calendar__tile--hasActive:enabled:focus {
//   background: #a9d4ff;
// }

// .react-calendar__tile--active {
//   background: #006edc;
//   color: white;
// }

// .react-calendar__tile--active:enabled:hover,
// .react-calendar__tile--active:enabled:focus {
//   background: #1087ff;
// }

// .react-calendar--selectRange .react-calendar__tile--hover {
//   background-color: #e6e6e6;
// }
