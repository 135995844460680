h2 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
}


*,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.h2,
h2 {
    font-size: 3rem;
}

.joinsession__wrapper {
    background: #33415c;
    padding: 100px;
    color: #fff;
    position: relative;
}

.joinsession__wrapper:before {
    position: absolute;
    content: url('../../../assets/Images/family.png');
    right: -3%;
    top: 10%;
    transform: scale(0.6);
}

.joinsession__wrapper:after {
    position: absolute;
    content: url('../../../assets/Images/enroll.png');
    left: -12%;
    top: 20%;
    transform: scale(0.6);
}

.joinsession__wrapper a.session__join {
    background: #33415c;
    color: #fff;
    font-weight: 700;
    border: solid 2px #fff;
    width: 32%;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    height: 50px;
    align-items: center;
    position: relative;
    z-index: 2;
}

.joinsession__wrapper a.session__join:hover {
    color: #33415c;
}

.joinsession__wrapper a.session__join:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: -1;
    transform: scale(0);
    transition: all linear 0.3s;
}

.joinsession__wrapper a.session__join:hover::after {
    transform: scale(1);
}

.joinsession__wrapper a.session__join .png {
    margin-left: 15px;
}

.session__sub {
    color: #ffab4a;
    font-size: 20px;
    font-weight: 700;
}

.session__title {
    padding: 5px 0 35px 0;
}

a,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

}

*,
:after,
:before {
    box-sizing: border-box;
}

/*-----------------------------------------------------------*/



@media only screen and (max-width: 991px) {

    .joinsession__wrapper:before,
    .joinsession__wrapper:after {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .joinsession__wrapper {
        padding: 60px 0px;
    }

    .joinsession__wrapper a.session__join {
        width: 50%;
    }
}

@media only screen and (max-width: 575.98px) {
    h1 {
        font-size: 36px;
        line-height: 45px;
    }

    h2 {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
    }
}

@media (min-width: 576px) {
    .offset-sm-0 {
        margin-left: 0;
    }
}

@media (min-width: 576px) {
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*-------------------------------------*/

.text-center {
    text-align: center !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

@media (min-width: 992px) {
    .offset-lg-5 {
        margin-left: 41.666667%;
    }
}

@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}


.element.style {
    overflow-x: hidden;
}

.margin__bottom {
    margin-bottom: 60px;
}

.section__title {
    h2 {
        font-size: 2rem;
        font-style: italic;
    }
}