.calendar-events {
  display: flex;
  margin: 15px;
}

.quick-link {
  width: 100%;
}
.title-discription {
  margin-left: 8px;
  word-wrap: break-word;
  word-break: break-all;
  margin-top: 15px;
}
.news-date-month {
  width: 100%;
  min-width: 35%;
  max-width: 40%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titles {
  margin-top: 8px;
  word-wrap: break-word;
  word-break: break-all;
}

.quick-links-cards {
  padding: 25px;
  height: 115px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  margin-bottom: 20px;
}

.react-calendar {
  width: 100%;
  border: 0px solid;

  abbr[title] {
    text-decoration: none;
  }
}

.react-calendar__tile--active {
  background: #1b3779;
  color: white;
}

active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1b3779;
}

.react-calendar__tile--now {
  background: #00bfff;
}

.scroll-container {
  // border: 3px solid black;
  border-radius: 5px;
  // height: 50%;
  // overflow: hidden;
  // width: 50%;
  margin: 18px;
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
  animation-play-state: running;
}

.scroll-container:hover {
  // border: 3px solid black;
  border-radius: 5px;
  // height: 50%;
  // overflow: hidden;
  // width: 50%;
  margin: 18px;
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 8s linear infinite;
  -webkit-animation: my-animation 8s linear infinite;
  animation: my-animation 8s linear infinite;
  animation-play-state: paused;
}

.dashboard-scroll-text {
  height: 100%;
  text-align: center;
  margin: 10px 0px;
}

.title-discription {
  text-align: start;
  margin-top: 3px;
}

.dashboard-news-content {
  display: flex;
  text-align: center;
  height: auto;
  width: 95%;
  margin-left: 1rem;
  margin-right: 2rem;
  padding: 0px;
  min-height: 100px;
  position: relative;
  margin-bottom: 10px;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
  }

  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
