.navtop-container {
    background: #fff;
    min-height: 60px;
    border-bottom: 4px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    height: auto;
}

.icon-items {
    i {
        margin-left: 7px;
        margin-right: 7px;
        color: #564fff;
        cursor: pointer;
        width: 22px;
        height: 27px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        transition: all .2s linear;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        transition-delay: 0s;
        transition-property: all;
        -webkit-transition: all .2s linear;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        transition-delay: 0s;
        transition-property: all;
        -moz-transition: all linear .2s;
    }
}

.contact-no {
    font-weight: 500;
    font-size: 12px;
    margin: 12px;
}

.contact-no {
    i {
        color: #564fff;
        margin: 7px;
    }
}

.nav-items {
    h3 {
        color: #007bff;
        display: inline-block;
        font-weight: 600;
        font-family: 'Times New Roman', Times, serif;
        margin-bottom: 0;
    }
}

.btn-language {
    display: flex;
    // width: 145px;
    cursor: pointer;
    border-radius: 7px;
}

.btn-language:hover {
    color: #564fff;

}

.topper-btn button {
    background: #fff;
    border-color: #ddd;
    border-width: 0px;
    cursor: pointer;
    margin: 7px;
    font-size: 12px;
    font-weight: 500;
}

.topper-btn button:hover {
    color: #564fff;
}

.topper-btn i {
    color: #564fff;
}

.btn-language .MuiButtonBase-root {
    .MuiButton-label {
        font-size: 12px;
        margin-top: 2px;

        &:hover {
            color: #564fff;
        }
    }
}

// .fa-solid {
//     margin-top: 15px !important;
// }

.ul-custom {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.custom-li {
    margin: 0px 15px;
    text-decoration: none;
    margin-right: -3px;
}

.custom-list {

    display: flex;
    margin: auto;
    font-size: 13px;
    font-weight: 500;
    margin-right: 25px;

    &:hover {
        color: #564fff;

    }

    i {
        margin-left: 7px;
        color: #564fff;

    }

}