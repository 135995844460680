* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif;
}

.css-edzic {
  padding: 0 !important;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");

.wrapper {
  position: relative;
  max-width: 430px;
  width: 100%;
  /* background: #fff; */
  background-color: #fff;
  /* backdrop-filter: blur(2px); */
  padding: 34px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.wrapper h2 {
  position: relative;

  font-size: 22px;

  font-weight: 600;

  color: #333;
}

.wrapper h2::before {
  content: "";

  position: absolute;

  left: 0;

  bottom: 0;

  height: 3px;

  width: 28px;

  border-radius: 12px;

  /* background: #4070f4; */
}

.wrapper form {
  margin-top: 30px;
}

.wrapper form .input-box {
  height: 52px;

  margin: 18px 0;
}

form .input-box input {
  height: 100%;

  width: 100%;

  outline: none;

  padding: 0 15px;

  font-size: 17px;

  font-weight: 400;

  color: #333;

  border: 1.5px solid #c7bebe;

  border-bottom-width: 2.5px;

  border-radius: 6px;

  transition: all 0.3s ease;
}

.input-box input:focus,
.input-box input:valid {
  border-color: #4070f4;
}

form .policy {
  display: flex;

  align-items: center;
}

form h3 {
  color: #707070;

  font-size: 14px;

  font-weight: 500;

  margin-left: 10px;
}

.input-box.button input {
  color: #fff;

  letter-spacing: 1px;

  border: none;

  background: #4070f4;

  cursor: pointer;
}

.input-box.button input:hover {
  background: #0e4bf1;
}

form .text h3 {
  color: #333;

  width: 100%;

  text-align: center;
}

form .text h3 a {
  color: #4070f4;

  text-decoration: none;
}

form .text h3 a:hover {
  text-decoration: underline;
}

.banner-scroll::-webkit-scrollbar {
  width: 1px;
  background-color: #f5f5f5;
}

.banner-scroll::-webkit-scrollbar-thumb {
  width: 1px;
  border-radius: 10px;
  background-color: gray;
}
/* 
.slick-prev:before {
  color: blue;
  font-size: 30px;
}

.slick-next:before {
  color: blue;
  font-size: 30px;
} */

.slick-arrow-icon-left,
.slick-arrow-icon-right {
  position: absolute;
  display: block;
  cursor: pointer;
  background: rgb(6, 143, 255);
  color: white;
  border-radius: 50px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  transition: 0.5s ease-in-out;
  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    color: white;
    &::before {
      opacity: 1;
    }
  }
}

.slick-arrow-icon-left {
  left: -50px;
  [dir="rtl"] & {
    left: auto;
    right: -50px;
  }
}

.slick-arrow-icon-right {
  right: -50px;
  [dir="rtl"] & {
    left: -50px;
    right: auto;
  }
}

.image {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

@keyframes uparrow {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(-0.4em);
    opacity: 0.9;
  }
}
