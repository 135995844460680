.tableHeader {
    background-color: #1B3779;
    color: white !important;
}
.tableCell {
    color: white !important;
}
.totalOverviewContainer {
    display: flex;
    gap: 1rem;
}