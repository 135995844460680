.funcContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tableContainer {
  margin-top: 1rem;
}
.dialog_select {
  width: 30% !important;
}
.dialogContainer {
  width: 500px;
}
.dialogContainerFlex {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}
.installmentsContainer {
  margin-top: 1.5rem;
}
.installmentsContainerFlex {
  display: flex;
  gap: 10px;
  padding: 1rem 0;
}
.tableActions {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .funcContainer {
    flex-direction: column;
  }
}
