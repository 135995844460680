table.alter tr:nth-child(even) {
  background-color: #eee;
}
table.alter tr:nth-child(odd) {
  background-color: #fff;
}
table.alter th {
  color: white;
  background-color: gray;
}

.App {
  text-align: center;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.cell:hover {
  opacity: 0.8;
}

/* .scrollable{
  overflow-x: scroll;
  width: 100px;
  height: 100px;
  flex:1;

} */

.scrollable {
  height: 200px;
  overflow-y: scroll;
}
.card {
  padding: 5px;
  /* margin-bottom: 10px; */
  margin: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
}

.scrollableCard {
  height: 250px;
  overflow-y: scroll;
}
.cardScroll {
  padding: 5px;
  margin: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
}

.noticescoll {
  overflow-y: scroll;
  height: 280px;
}
