.dnews {
  font-size: 15px;
  padding-left: 6px;
  color: rgba(240, 7, 7, 0.658);
  border-left: 1px solid grey;
}

.image {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.1);
}

.background {
  background-image: url("https://excellentschool.in/static/media/page-banner-1580211983-sms.eec9af5325dc9cecbb3b.jpg");

  background-repeat: no-repeat;

  background-position: center;

  object-fit: cover;

  height: 30vh;

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;
}
.month {
  color: white;
  padding-left: 25%;
  line-height: 1.5;
  font-weight: 500;
}
.date {
  color: #ffffff;
  padding-left: 50%;
  line-height: 1;
}

/* @media screen and (max-width:950px) {
  .month{
    padding-left: 10%;
  }
  
} */
