.why__img img {
    width: 647px;
    height: 392px;
    // object-fit: cover;
    background-color: #33415c;
}

.why__right__back {
    background: #33415c;
}

.why__right {
    color: #fff;
    padding: 51px;
    width: 100%;
    text-align: justify;
}

.why__right .list__one {
    display: flex;
    padding-top: 35px;
}

.list__one__content h5 {
    padding-bottom: 8px;
}

.why__right .list__one__half {
    background: #fff;
    height: 80px;
    width: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.why__right .list__one__content {
    flex: 0 0 83%;
    padding-left: 28px;
}

.why__img {
    position: relative;
    background: #33415c;
}

.why__img .video__btn {
    position: absolute;
    top: 45%;
    left: 45%;
    background: #fff;
    color: #33415c !important;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    color: #fff;
    font-size: 19px;
}

.why__img .video__btn:hover {
    background: #ff564f;
}

.why__img .video__btn:hover svg {
    color: #fff;
}

.section__contnet {
    font-style: oblique;
    margin-top: 0;
    margin-bottom: 0rem;
    margin-top: 20px;
}

.section__title {
    font-size: 2rem;
}

.chairman-title {
    text-align: right;
    font-style: oblique;
    font-size: 16px;
    margin-top: 20px;
    color: white;
}