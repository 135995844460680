.teacher__img img {
    width: 100%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    ;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
}

.teacher__single {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}

.teacher__single:hover .teacher__img img {
    transform: scale(1.1);
}

.teacher__single .teacher__content {
    position: absolute;
    top: 54%;
    background: #fff;
    left: 18%;
    width: 65%;
    height: 20%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    ;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.teacher__content:hover {
    height: 28%;
}

.teacher__content:hover .teacher__content__in {
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    transform: translateY(0px);
}

.teacher__content:hover ul.teacher__social {
    visibility: visible;
}

ul.teacher__social {
    display: flex;
    justify-content: center;
    visibility: hidden;
}

.teacher__content__in {
    transform: translateY(18px);
}

ul.teacher__social li {
    padding: 0 7px;
    font-size: 14px;
}

.teacher__content h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.teacher__content h6 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}

.more__teacher {
    position: relative;
    padding-bottom: 20px;
    top: -25px;
}

.more__teacher a.teacher__btn {
    color: #383838;
    font-weight: 700;
    border: solid 2px #33415c;
    width: 15%;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    height: 50px;
    align-items: center;
    margin: 0 20px;
    position: relative;
}

.more__teacher a.teacher__btn:hover {
    color: #fff;
}

.more__teacher a.teacher__btn:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #33415c;
    color: #fff !important;
    z-index: -1;
    transform: scale(0);
    transition: all linear 0.3s;
}

.more__teacher a.teacher__btn:hover::after {
    transform: scale(1);
}

.more__teacher a.teacher__btn svg {
    margin-left: 15px;
}

.more__teacher a.teacher__quali {
    font-size: 20px;
    font-weight: 700;
    color: #383838;
}

.more__teacher a.teacher__car {
    font-size: 20px;
    font-weight: 500;
    color: #ff564f;
    position: relative;
    width: 70px;
    display: inline-block;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
}

.more__teacher a.teacher__car:after {
    position: absolute;
    content: "";
    width: 35%;
    height: 3px;
    bottom: 0px;
    left: 0;
    background: #ff564f;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;

}

.more__teacher a.teacher__car:hover {
    letter-spacing: 2px;
}

.more__teacher a.teacher__car:hover::after {
    width: 115%;

}

.more__teacher {
    i {
        margin-left: 10px;
    }
}