.container {
  padding: 1rem;
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
}
.tableContainer {
  margin-top: 1rem;
}
.actionContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
}
