.logo {
  color: white;
  cursor: pointer;
  transition: 1s;
}


.logo:hover {
  color: #F86F03;
}

.logobox {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
  align-items: center;
}

.firstgridPara {
  font-size: 1rem;
  color: white;
  font-family: sans-serif;
}

.firstgridSecond {
  font-size: 1.4rem;
  font-weight: bold;
  color: #F86F03;
}

.title1 {
  color: #eeeeee;
  font-family: serif;
  font-size: 1rem;
}

.title2 {
  color: #ffffffb3;
  font-family: serif;
  font-size: "0.8rem";
}

.usefull {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  /* border: solid red; */
}

.usefull a {
  text-decoration: none;
  font-family: sans-serif;
  color: white;
  transition: 0.8s;
}
.usefull a:hover {
  color: #F86F03;
}

.footerLink a {
  text-decoration: none;
  color: #ffffff;
  font-family: sans-serif;
}

.footerLink {
  display: flex;
  gap: 1rem;
  margin-right: 3rem;
  margin-bottom: 5vh;
}

.copyright {
  border: 1px solid black;
}

.ecampusheading{
  color: white;
  text-decoration: none;
  transition: 1s;
}

.ecampusheading:hover .ecampus{
  transition: 1s;
   color: #F86F03;
}


@media screen and (max-width: 400px) {
  .footerLink {
    margin-top: 1rem;
    flex-wrap: wrap;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .copyright {
    text-align: center;
    padding: 2rem;
  }
}


