.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 12px !important;
}

// .divStyle {
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   height: 80%;
// }

// .imageStyles {
//   height: 100vh;
//   background-image: url("./school3.png");
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }

.rememberStyle {
  font-size: smaller;
  font-weight: 500;
  padding-left: 100;
}

.errorStyle {
  color: red;
}
.imageStyless {
  height: 100vh;

  background-image: url("../../../assets/Images/school2.jpg");

  -webkit-background-size: cover;

  background-repeat: no-repeat;

  background-size: cover;
}

.divStyle {
  display: flex;

  justify-content: center;

  align-items: center;

  display: grid;
}

.form {
  background: rgba(255, 255, 255, 0);

  background-color: transparent;

  place-content: center;

  // position: absolute;

  // top: 20%;

  // left: 50%;

  // transform: translate(-50%, -50%);

  border: 1px solid #ffff;

  // border: none;

  border-radius: 10px;
  padding: 20px;

  box-shadow: 0 15px 15px rgba(255, 255, 255, 0.3);

  box-sizing: border-box;
}
