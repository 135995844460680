.sideContent {
  color: #fff;
}
.divider {
  max-height: 35px;
  color: orangered;
  width: 5px;
  font-size: 5px;
  border: 1px solid orangered;
  border-radius: 2px;
  background-color: orangered;
  font-weight: 600px;
}
.title {
  font-size: 25px;
  /* margin-top: 8px; */
}
.sideTitle {
  font-size: 50px;
  color: #fff;
  font-weight: 600px;

  letter-spacing: 0.2rem;
  font-family: "Roboto Slab", serif;
}
.rotate {
  animation: rotation 100s infinite linear;
  font-size: 16px;
  font-weight: 400;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
