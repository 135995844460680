@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes swiper {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
  }
  50% {
    transform: perspective(400px) rotateX(180deg);
  }
  100% {
    transform: perspective(400px) rotateY(0);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* @keyframes zoom-out {
  
        from {
  
          transform: scale(1);
  
        }
  
        to {
  
          transform: scale(0);
  
        }
  
      } */

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.6);
  }
  70% {
    transform: scale(0.7);
  }
  80% {
    transform: scale(0.8);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate-scale {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

/* @keyframes fade-out {
  
        from {
  
          opacity: 0;
  
        }
  
        to {
  
          opacity: 1;
  
        }
  
      } */

@keyframes fade-out {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(0.6);
  }
  60% {
    transform: scale(0.4);
  }
  80% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-in {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rotate-x {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

@keyframes cube {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: rotateX(90deg) rotateY(0deg);
  }
  50% {
    transform: rotateX(90deg) rotateY(180deg);
  }
  75% {
    transform: rotateX(90deg) rotateY(180deg);
  }
  100% {
    transform: rotateX(0deg) rotateY(180deg);
  }
}

@keyframes box {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes line-by-line {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes louvers {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@keyframes brick1 {
  0% {
    transform: translateX(-100%) scale(1);
  }
  25% {
    transform: translateX(-50%) scale(0.5);
  }
  50% {
    transform: translateX(0%) scale(1);
  }
  25% {
    transform: translateX(50%) scale(-0);
  }
  50% {
    transform: translateX(100%) scale(-1);
  }
}

@keyframes bricks {
  0% {
    transform: translateX(-100%) scale(1);
  }
  10% {
    transform: translateX(-90%) scale(0.8);
  }
  20% {
    transform: translateX(-80%) scale(0.6);
  }
  30% {
    transform: translateX(-70%) scale(0.4);
  }
  40% {
    transform: translateX(-60%) scale(0.2);
  }
  50% {
    transform: translateX(-50%) scale(0.1);
  }
  60% {
    transform: translateX(-40%) scale(0.2);
  }
  70% {
    transform: translateX(-30%) scale(0.4);
  }
  80% {
    transform: translateX(-20%) scale(0.6);
  }
  90% {
    transform: translateX(-10%) scale(0.8);
  }
  100% {
    transform: translateX(0%) scale(1);
  }
}
