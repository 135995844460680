* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.facebookLogo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 25px;
  background-color: green;
  display: flex;
  z-index: 20;

  align-items: center;
  justify-content: center;
}
.facebookLogo:hover {
  background-color: rgb(68, 103, 177);
}
.twitterLogo {
  height: 30px;
  z-index: 20;

  width: 30px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.twitterLogo:hover {
  background-color: rgb(29, 161, 242);
}
.linkdinLogo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
}
.linkdinLogo:hover {
  background-color: rgb(29, 161, 242);
}
.mainLogo {
  display: flex;
  justify-content: space-evenly;
  z-index: 1;
  display: none;
  width: 140px;
}

/* .primaryGrid {
    position: relative;
    overflow: hidden;
  }
 */
.pic {
  transition: 0.4s ease-in-out;

  /* height: 412px; */
  /* width:310px; */
  /* width: 200px; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* object-fit: cover; */
  /* background-size: 100% 100%; */
  /* background-size: cover;
    background-position: center; */
  /* background-color: yellow; */
}
.mainGrid {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  width: fit-content;
  overflow: hidden;
}

.mainGrid:hover .mainLogo {
  position: absolute;
  overflow: hidden;
  bottom: 50%;
  /* top:18%; */
  left: 20%;
  display: flex;
  gap: 5%;
  justify-content: center;
  align-items: center;
  /* width: 100px; */
}

/* .secondaryGrid {
    box-shadow: 1px 1px 2px 2px rgb(223 219 219 / 60%);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    text-align:left; 
    gap: 25px;
    flex-direction: column;
     justify-content:space-between; 

  } */

.mainGrid:hover .pic {
  opacity: 0.6;
  /* opacity:.1; */
  /* background-color:#5e5e5e; */
}
.mainGrid:hover .overlay {
  display: block;
  /* background-color:"rgba(0,0,0,0.5)"; */
  /* transition:"opacity 0.3 ease"; */
  /* opacity: 0.5; */
  /* opacity:0.5; */
  /* background-color:rgba(0,0,0,0.5); */
  /* opacity:1; */
  /* background-color:#5e5e5e; */
}

.heading {
  /* font-weight: 600; */
  /* text-transform: uppercase; */
}

.mainGrid:hover .heading {
  color: #80cd91;
}

/* .designation {
    font-size: 16px;
    color:"#604232"
  }
   */
/* .description {
    font-size: 15px;
    color: #947c7a;
  } */
.footname {
  font-size: 14px;
  color: #323232;
  text-transform: uppercase;
  font-weight: 500;
}
.mainGrid:hover .footname {
  color: #80cd91;
}

.topButton {
  position: fixed;
  /* height: 50px;
    width: 50px; */
  z-index: 10;
  bottom: 20px;
  right: 15px;
  cursor: pointer;
}

/* blog css code */
.smallImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.mainGrid:hover .titleDesignation {
  color: rgb(128, 205, 54);
}

/* .mainGrid:hover .coverImage{
    opacity: 0.4;
  } */

/* form css code */
.mainBox:hover .headingTitle {
  color: #80cd36;
}

.coursedetails {
  display: flex;
  padding-left: 2%;
  /* padding-right: 2%; */
  padding-top: 5%;
  text-transform: uppercase;
}

.bigg {
  color: #80cd36;
}

.mainBox:hover .headingTitle {
  color: #80cd36;
}
