.link {
  color: #cdcdcd;
  text-decoration: none;
}

.link :hover {
  border-bottom: 1px solid #ff8f0b;
  color: #ff8f0b;
  transition: 0.8s;
  transition-timing-function: ease-in-out;
}

@media only screen and (max-width: 600px) {
  .address {
    font-size: 14px;
  }
}
.facebook {
  color: #cdcdcd;
  text-decoration: none;
}

.facebook :hover {
  color: #195eff;
}

.twitter {
  color: #cdcdcd;
  text-decoration: none;
}

.twitter :hover {
  color: #179cf0;
}
.instagram {
  color: #cdcdcd;
  text-decoration: none;
}

.instagram :hover {
  color: #f20880;
}

.youtube {
  color: white;
  cursor: pointer;
}
.youtube:hover {
  color: red;
}

.likedin {
  color: white;
  cursor: pointer;
}
.likedin:hover {
  color: #2196f3;
}

.logo {
  color: white;
  cursor: pointer;
  transition: 1s;
}

.logo:hover {
  color: #f86f03;
}
